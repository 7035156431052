import React from 'react';
import Page from '../components/page';
import Hero, { HeroBody } from '../components/hero';
import Container from '../components/container';
import Columns, { Column } from '../components/columns';
import { FaqSection, Question, Answer } from '../components/faqs';
import Text from '../components/text';


function QA(){
	return (
		<Container>
			<FaqSection>
				<Question>
					What is zeitbox?
				</Question>
				<Answer>
					zeitbox runs on your local laptop/desktop and lets you
					offload computationally heavy workloads to the cloud while
					keeping your native development workflow intact. In other
					words, you don't even notice its running! Your computer
					just happens to get super powers.
				</Answer>
			</FaqSection>

			<FaqSection>
				<Question>
					How does it work?
				</Question>
				<Answer>
					Under the hood, zeitbox uses containerization technology to
					migrate processes between running locally on your machine
					and in the cloud. As a user, you won't see the
					difference. So if someone asks, you can says it works by
					magic.
				</Answer>
			</FaqSection>

			<FaqSection>
				<Question>
					I'm a hardcore hacker. How do I dig deeper?
				</Question>
				<Answer>
					Our aim is to make zeitbox fully cross-compatible with
					Docker. So anything you can do with Docker, you'll be able
					to do with zeitbox. We're currently in the process of
					implementing this.
				</Answer>
			</FaqSection>

			<FaqSection>
				<Question>
					How is this different to Docker and Kubernetes?
				</Question>
				<Answer>
					Docker and Kubernetes let you build deployable
					<b><i> production </i></b> apps. zeitbox uses these
					technologies to supercharge your <b><i> development </i></b>
					workflow. It just happens to let you deploy your app to the
					cloud if you really want to.
				</Answer>
			</FaqSection>

			<FaqSection>
				<Question>
					What development environments can I configure?
				</Question>
				<Answer>
					Since our goal is to be cross-compatible with Docker, you
					can configure any environment (Docker image) that you would
					otherwise be able to configure with Docker.
				</Answer>
			</FaqSection>

			<FaqSection>
				<Question>
					Does this integrate with my Git workflow?
				</Question>
				<Answer>
					Yes! You can save the state of your dev-environment with each git commit.
				</Answer>
			</FaqSection>

			<FaqSection>
				<Question>
					Do I need a Google Cloud or AWS account?
				</Question>
				<Answer>
					No. We manage all the cloud computing madness. You only pay for the exact number of seconds of
					cloud compute you use!
				</Answer>
			</FaqSection>

			<FaqSection>
				<Question>
					Can I self host?
				</Question>
				<Answer>
					Not yet - but soon!
				</Answer>
			</FaqSection>

			<FaqSection>
				<Question>
					What platforms do you support?
				</Question>
				<Answer>
					Linux, MacOS and Windows.
				</Answer>
			</FaqSection>

			<FaqSection>
				<Question>
					Do you have an API reference?
				</Question>
				<Answer>
					Coming soon.
				</Answer>
			</FaqSection>
		</Container>
	)
}

export default function Faqs({ location }) {
	return (
		<Page>
			<Hero color="white" s style={{ flex: 1 }}>
				<HeroBody style={{ alignItems: 'flex-start' }}>
					<Container>
						<Columns className="is-vcentered is-centered">
							<Column size={8}>
								<Text h3>
									Frequently asked questions.
								</Text>

								<QA/>

							</Column>
						</Columns>
					</Container>
				</HeroBody>
			</Hero>
		</Page>
	)
}