import React from 'react';
import Page from '../components/page';
import ForgotPasswordForm from '../components/forms/forgotpassword';
import Container from '../components/container';
import Columns, { Column } from '../components/columns';
import Hero, { HeroBody } from '../components/hero';

export default function ForgotPassword() {
	return (
		<Page>
			<Hero color="white" style={{ flex: 1 }}>
				<HeroBody>
					<Container>
						<Columns className="is-vcentered is-centered">
							<Column size={8}>
								<ForgotPasswordForm />
							</Column>
						</Columns>
					</Container>
				</HeroBody>
			</Hero>
		</Page>
	)
}