import React from 'react';
import Page from '../../components/page';
import Hero, { HeroBody } from '../../components/hero';
import Container from '../../components/container';
import BrandSearch from '../../components/brandsearch';
import AuthContext from '../../components/user/auth/context';

export default function UserHome() {
	return (
		<Page>
			<Hero style={{ flex: 1 }}>
				<HeroBody style={{ alignItems: 'flex-start' }}>
					<Container>
						<AuthContext.Consumer>
							{user => Boolean(user) && <BrandSearch />}
						</AuthContext.Consumer>
					</Container>
				</HeroBody>
			</Hero>
		</Page>
	);
}