export function requestInvite(email) {
	return fetch(`${process.env.REACT_APP_HTTPS_FUNCTIONS_DOMAIN}/api/requestInvite`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({ email })
	}).then(res => {
		if (!res.ok) {
			return res.text().then(text => Promise.reject(new Error(text)));
		}

		return res;
	});
}

export function signUpWithInvite(gender, password, inviteKey) {
	return fetch(`${process.env.REACT_APP_HTTPS_FUNCTIONS_DOMAIN}/api/signUpWithInvite`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({ password, inviteKey, gender })
	}).then(res => {
		if (!res.ok) {
			return res.text().then(text => Promise.reject(new Error(text)));
		}

		return res;
	});
}
