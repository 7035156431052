import React, { useEffect, useState } from 'react';
import Text from '../text';
import Columns, { Column } from '../columns';
import Image from '../image';
import { fetchSizesForBrand, recommendSize } from '../../lib/brandsearch';
import Spinner from '../spinner';
import Link from '../link'

function SizeGuide({ sizes }) {
	return Object.values(sizes).map(sizeArray => {
		return sizeArray.map((size, index) => {
			return <Text key={`${size.metric}_${index}`}>
				<strong>Garment:</strong> {size.garment}/{size.garmentDetail} <strong>Sizes:</strong> {Object.keys(size.sizes).map(sizeName => {
					return `${sizeName}: ${size.sizes[sizeName]}`;
				}).join(',')} <strong>Metric:</strong> {size.metric}
			</Text>
		})
	})
}

export default function Brand({ brand, userMeasurement, gender }) {
	const [sizes, setSizes] = useState(null);
	const [recommendedSize, setRecommendedSize] = useState(null);
	const [showSizeGuide, setShowSizeGuide] = useState(false);


	useEffect(() => {
		if (sizes === null && userMeasurement) {
			fetchSizesForBrand(brand.id, userMeasurement, gender).then(sizes => {
				setSizes(sizes);
				setRecommendedSize(recommendSize(sizes))
			});
		}
	})

	if (recommendedSize === 'empty') {
		return null;
	}

	return (
		<div onClick={() => setShowSizeGuide(showSizeGuide => !showSizeGuide)}>
			<Columns className="is-mobile is-vcentered brand-list">
				<Column size={2}>
					<Image
						src={brand.logo}
						alt={`${brand.name} logo`}
						style={{
							padding: '10%'
						}} />
				</Column>
				<Column offset={1} size={6} className="has-text-centered">
					{recommendedSize ?
						<Text>{recommendedSize}</Text> : <Spinner />
					}
				</Column>
				{/* <Column size={4} className="has-text-centered">
					<Text h4>
						{brand.name}
					</Text>
				</Column> */}
				<Column size={3}>
					<Link to=''>
						Website
				</Link>
				</Column>
			</Columns>
			{showSizeGuide && <Columns className="is-mobile is-vcentered has-text-left">
				<Column>
					{sizes ?
						<SizeGuide sizes={sizes} />
						:
						<Spinner />}
				</Column>
			</Columns>}
		</div>
	)
}