import React from 'react';
import Page from '../components/page';
import Text from '../components/text';
import Image from '../components/image';
import InviteForm from '../components/forms/invite';
import Container from '../components/container';
import Hero, { HeroBody } from '../components/hero';
import Columns, { Column } from '../components/columns';
import AuthContext from '../components/user/auth/context';
import Redirect from '../components/redirect';
import { getUserName } from '../lib/user/name';
import Button from '../components/button';
import Link from '../components/link';

export default function Home() {
    return (
    <Page>
        <AuthContext.Consumer>
            {user => Boolean(user) && <Redirect to={`/${getUserName()}`} />}
        </AuthContext.Consumer>

        <Hero style={{ flex: 1 }}>
            <HeroBody>
                <Container className='' style={{padding: '50px'}}>

                    <Columns className="is-vcentered is-centered">
                        <Column size={6}>
                            <Columns className="is-multiline">

                                <Column size={12}>
                                    <h2 style={{fontSize: '30Px', fontWeight: 'bold', color: '#ffce82'}}>Supercharge your computer </h2>
                                    <p style={{fontSize: '16Px'}}>
                                        Maintain native work-flows on your local machine and
                                        let the cloud do the heavy lifting.
                                    </p>

                                </Column>

                            </Columns>
                        </Column>
                        <Column size={6}>
                            <img src={'/images/cycle.svg'}/>
                        </Column>
                    </Columns>

                    <Columns className="is-vcentered is-centered">
                        <Column className="is-vcentered is-centered" size={8}>
                            <h2 style={{fontSize: '30Px', fontWeight: 'bold', textAlign: 'center'}}>Coming soon ... </h2>
                        </Column>
                    </Columns>

                </Container>
            </HeroBody>
        </Hero>
    </Page>
    );
}