import firebase from 'firebase/app';
import 'firebase/auth';

export function registerOnAuthChangeListener(callback) {
	return firebase.auth().onAuthStateChanged(callback);
}

export function createUser({ email, password, ...userData }) { }

export function deleteUser() {
	return firebase.auth().currentUser.delete();
}

export function signIn(email, password) {
	return firebase.auth().signInWithEmailAndPassword(email, password);
}

export function signOut() {
	return firebase.auth().signOut();
}

export function getCurrentUser() {
	return firebase.auth().currentUser;
}

export function sendPasswordResetEmail(email) {
	return firebase.auth().sendPasswordResetEmail(email)
		.catch(error => {
			switch (error.code) {
				case 'auth/invalid-email':
					error.message = 'Please enter a valid email adress.';
					break;
			}

			return Promise.reject(error);
		});
}