import React, { useState, useEffect, useContext } from 'react';

import Link from '../../components/link';
import Page from '../../components/page';
import Button from '../../components/button'

import Container from '../../components/container';
import Hero, { HeroBody } from '../../components/hero';
import Columns, { Column } from '../../components/columns';
import AuthContext from '../../components/user/auth/context';

export default function Size({ location }) {
	const [isOpen, setOpen] = useState(false);

	return (
		<Page>
			<Hero color="white" style={{ flex: 1 }}>
				<HeroBody style={{ alignItems: 'flex-start' }}>
					<Container>
						<Columns className="is-vcentered is-centered">
							<Column size={8}>
							</Column>
						</Columns>
					</Container>
				</HeroBody>
			</Hero>
		</Page >
	)
}