import React from 'react';
import Page from '../components/page';
import Hero, { HeroBody } from '../components/hero';
import Container from '../components/container';
import Columns, { Column } from '../components/columns';
import Text from '../components/text';
import SignupForm from '../components/forms/signup';
import InviteForm from '../components/forms/invite';
const qs = require('qs');

export default function Signup({ location }) {
	const { inviteKey, email } = qs.parse(location.search, { ignoreQueryPrefix: true });

	return (
		<Page>
			<Hero color="white" style={{ flex: 1 }}>
				<HeroBody>
					<Container>
						<Columns className="is-vcentered is-centered">
							<Column size={8}>
								{inviteKey && email ?
									<SignupForm email={email} inviteKey={inviteKey} />
									:
									<>
										<Text h2>We're sorry but zeitbox is currently invite only. <br /></Text>
										<Text className="subtitle">
											If you'd like early access please enter you email address and we'll send you a sign-up link as soon as we're ready.
										</Text>
										<InviteForm />
									</>
								}
							</Column>
						</Columns>
					</Container>
				</HeroBody>
			</Hero>
		</Page>
	)
}