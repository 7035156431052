import firebase from 'firebase/app';
import 'firebase/firestore';

export const BRANDS_COLLECTION = "brands_import_test";

function docToDataWithId(doc) {
	return {
		id: doc.id,
		...doc.data()
	}
}

function filterSizeRange(userSize) {
	return function (data) {
		return data.lower <= userSize && data.upper > userSize;
	}
}

export function fetchBrands() {
	return firebase.firestore().collection(BRANDS_COLLECTION).get()
		.then(snapshot => snapshot.docs.map(docToDataWithId));
}

// Returns a prmise that resolves into 
// an object containing the recommended sizes 'shoulder', 'chest', 'waist', 'hips'.
// The returned object looks like this:
// {
// 	shoulder: [array of matching sizes for the users measurement],
// 	chest: [array of matching sizes for the users measurement],
// 	waist: [array of matching sizes for the users measurement],
// 	hips: [array of matching sizes for the users measurement],
// }
// Example:
// {
// 	"shoulder": [],
// 	"chest": [
// 		{
// 			"id": "M_chest",
// 			"lower": 96,
// 			"metric": "chest",
// 			"size": "M",
// 			"upper": 104
// 		}
// 	],
// 	"waist": [
// 		{
// 			"id": "M_waist",
// 			"lower": 81,
// 			"metric": "waist",
// 			"size": "M",
// 			"upper": 89
// 		},
// 		{
// 			"id": "S_waist",
// 			"lower": 73,
// 			"metric": "waist",
// 			"size": "S",
// 			"upper": 81
// 		}
// 	],
// 	"hips": [
// 		{
// 			"id": "S_hips",
// 			"lower": 88,
// 			"metric": "hips",
// 			"size": "S",
// 			"upper": 96
// 		}
// 	]
// }
export function fetchSizesForBrand(brandId, userMeasurement, gender) {
	const metricQuery = (metric) =>
		firebase.firestore().collection(`${BRANDS_COLLECTION}/${brandId}/sizes`)
			.where('metric', '==', metric)
			.where('gender', '==', gender)
			.get();

	const metrics = ["Chest_cm", "Shoulders_cm", "Hips_cm", "Waist_cm"/*, "Height_cm", "Sleeve_Length_cm"*/];

	return Promise.all(metrics.map(metricQuery))
		.then((snapshots) => snapshots.map((snapshot) => {
			const sizeDocs = snapshot.docs;
			return sizeDocs.map(docToDataWithId);
		}))
		.then(sizeDocsArray => {
			// [[shoulder docs], [chest docs], [waist docs], [hips docs]]
			let sizeObj = {};
			metrics.forEach((metric, index) => {
				sizeObj[metric] = sizeDocsArray[index].filter(
					filterSizeRange(userMeasurement[metric])
				);
			});
			return sizeObj;
		});
}

// Returns the size that occurs the most in the sizeObject returned form fetchSizesForBrand.
// This is not a good way to recommend a size.
// For the following example it returns 'S'.
// {
// 	"shoulder": [],
// 	"chest": [
// 		{
// 			"id": "M_chest",
// 			"lower": 96,
// 			"metric": "chest",
// 			"size": "M",
// 			"upper": 104
// 		}
// 	],
// 	"waist": [
// 		{
// 			"id": "S_waist",
// 			"lower": 73,
// 			"metric": "waist",
// 			"size": "S",
// 			"upper": 81
// 		}
// 	],
// 	"hips": [
// 		{
// 			"id": "S_hips",
// 			"lower": 88,
// 			"metric": "hips",
// 			"size": "S",
// 			"upper": 96
// 		}
// 	]
// }
export function recommendSize(sizeObj) {
	let sizeCount = {};

	Object.values(sizeObj).forEach(sizeArray => {
		sizeArray.forEach(sizeData => {
			sizeCount[sizeData.sizes.Size_Brand] = typeof sizeCount[sizeData.sizes.Size_Brand] === 'number' ? sizeCount[sizeData.sizes.Size_Brand] + 1 : 1;
		})
	});

	let recommendedSize = "empty";
	let biggestCount = 0;

	Object.keys(sizeCount).forEach(size => {
		const count = sizeCount[size];
		if (count > biggestCount) {
			recommendedSize = size;
			biggestCount = count;
		}
	});
	console.log(sizeCount);

	return recommendedSize;
	// const sizes = ["Size_Brand", "Sizes_US", "Sizes_EU", "Sizes_IT", "Sizes_AU", "Sizes_RU", "Sizes_UK", "Sizes_FR", "Size_JP", "Size_DE", "Size_SP"];

	// let firstFoundSize = "error";
	// Object.values(sizeObj).find(metricSizes => {
	// 	return metricSizes.find(metric => {
	// 		const sizeName = sizes.find(size => {
	// 			return Boolean(metric.sizes[size]);
	// 		});
	// 		firstFoundSize = metric.sizes[sizeName];
	// 		return sizeName;
	// 	})
	// });

	// return firstFoundSize;
}