import React from 'react';
import Page from '../components/page';
import Hero, { HeroBody } from '../components/hero';
import Container from '../components/container';
import Columns, { Column } from '../components/columns';
import SigninForm from '../components/forms/singin';


export default function Signin({ location }) {
	return (
		<Page>
			<Hero color="white" style={{ flex: 1 }}>
				<HeroBody>
					<Container>
						<Columns className="is-vcentered is-centered">
							<Column size={8}>
								<SigninForm />
							</Column>
						</Columns>
					</Container>
				</HeroBody>
			</Hero>
		</Page>
	)
}