import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Home from './pages';
import Signup from './pages/signup';
import Signin from './pages/signin';
import UserHome from './pages/user';
import Size from './pages/user/size';
import Faqs from './pages/faqs';
import ForgotPassword from './pages/forgotpassword';
import Contact from './pages/contact';


function App() {
	return (
		<BrowserRouter>
			<Switch>
				<Route path="/faqs" component={Faqs} />
				<Route path="/forgotpassword" component={ForgotPassword} />
				<Route path="/contact" component={Contact} />
				<Route path="/signup" component={Signup} />
				<Route path="/signin" component={Signin} />
				<Route path="/:username/size" component={Size} />
				<Route path="/:username" component={UserHome} />
				<Route path="/size" component={Size} />
				<Route exact path="/" component={Home} />
			</Switch>
		</BrowserRouter>
	);
}

export default App;
