import React, { useState, useEffect } from 'react';
import { registerOnAuthChangeListener } from '../../../lib/user/auth';
import AuthContext from './context';

export default function UserAuth({ children }) {
	const [user, setUser] = useState(null);

	useEffect(() => {
		return registerOnAuthChangeListener(setUser);
	}, []);

	return (
		<AuthContext.Provider value={user}>
			{children}
		</AuthContext.Provider>
	);
}