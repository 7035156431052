import React, { useState } from 'react';
import Text from '../../text';
import Button from '../../button';
import Buttons from '../../buttons';
import Redirect from '../../redirect';
import { signIn, sendPasswordResetEmail } from '../../../lib/user/auth';
import Link from '../../link';
import Image from '../../image';
import { getUserName } from '../../../lib/user/name';

export default function SigninForm() {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [success, setSuccess] = useState(false);

	function onPasswordChange(event) {
		setPassword(event.target.value);
	}

	function onEmailChange(event) {
		setEmail(event.target.value);
	}

	function onSubmit() {
		setLoading(true);
		signIn(email, password)
			.then(() => {
				setLoading(false);
				setSuccess(true);
				setError("");
			})
			.catch(error => {
				setError(error.message);
				setLoading(false);
				setSuccess(false);
			});
	}

	return (
		<div style={{ maxWidth: '340px', margin: 'auto' }}>
			<div className='auth-header'>
				<Link to='/'>
					{/*<Image className='kleid-icon' src="/images/kleidSymbol.svg" />*/}
				</Link>
				<Text h3>Sign in to zeitbox</Text>
			</div>
			<div className='auth-form'>
				<div className="form-body">

					<div className="field">
						<label className='label'>Email</label>
						<div className="control">
							<input className="input"
								value={email}
								onChange={onEmailChange}
								type="email" />
						</div>
					</div>

					<div className="field">
						<label className='label'>Password</label>
						<div className="control">
							<input className="input"
								value={password}
								onChange={onPasswordChange}
								type="password" />
						</div>
					</div>

					<div className="field">
						<div className="control auth-button">
							<button onClick={onSubmit}
								className={`button is-primary is-outlined ${loading && 'is-loading'}`}>
								Sign in
							</button>
						</div>
					</div>

					<Text className="help is-danger form-error-centered">{error}</Text>
					{success && <Redirect to={`/${getUserName()}`} />}
				</div>
			</div>

			<div className='auth-footer'>
				<Buttons>
					<Button Component={Link} text to="forgotpassword">
						Forgot Password
					</Button>
					<Button Component={Link} to="/signup" text>
						Request invite
					</Button>
				</Buttons>
			</div>
		</div>
	);
}