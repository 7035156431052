import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/firestore';

export function fetchMostRecentMeasurement(realtime = false, realtimeCallback) {
	const query = firebase.firestore()
		.collection(`users/${firebase.auth().currentUser.uid}/measurements`)
		.orderBy('timestamp', 'desc')
		.limit(3);

	function snapshotCallback(snapshot) {
		if (snapshot.size === 0) {
			throw new Error('No measurements found');
		}
		console.log(snapshot.docs[0].data())

		return snapshot.docs[0].data();
	}

	if (realtime) {
		return query.onSnapshot(snapshot => {
			try {
				realtimeCallback(snapshotCallback(snapshot));
			} catch (error) {
				realtimeCallback(null);
			}
		});
	}

	return query.get().then(snapshotCallback);
}

// export function fetchMostRecentMeasurement() {
// 	//"Chest_cm", "Shoulders_cm", "Hips_cm", "Waist_cm"
// 	return Promise.resolve({
// 		// api outputs in cm
// 		shoulderWidth: 100,
// 		chestWidth: 97,
// 		waistWidth: 81,
// 		hipsWidth: 90,
// 		// estimated circumfrance
// 		Shoulders_cm: 100,
// 		Chest_cm: 97,
// 		Waist_cm: 81,
// 		Hips_cm: 90,
// 	});
// }

export function computeMeasurements(measurements, height) {
	var torsoToHeightRatio = 0.264;
	var ellipseMajorToMinorAxisRatioChest = 1.358;
	var ellipseMajorToMinorAxisRatioWaist = 1.333;
	var ellipseMajorToMinorAxisRatioHips = 1.31;

	function circumfrance(majorAxisLength, minorAxisLength) {
		var a = majorAxisLength / 2.0;
		var b = minorAxisLength / 2.0;
		var c = Math.PI * (3 * (a + b) - Math.sqrt((3 * a + b) * (a + 3 * b)));
		return c;
	}
	return new Promise(
		(resolve, reject) => {
			if (measurements.length === 0) {
				reject(new Error('No measurements to compute'));
			}

			measurements.sort((a, b) => {
				return b.shoulderWidthPx - a.shoulderWidthPx;
			})

			// Calucate cm per pixel
			let torsoRightPx = Math.abs(measurements[0].shoulderRightPx[1] - measurements[0].waistRightPx[1]);
			let torsoLeftPx = Math.abs(measurements[0].shoulderLeftPx[1] - measurements[0].waistLeftPx[1]);
			let torsoHeightPx = (torsoLeftPx + torsoRightPx) / 2;
			let cmPerPixel = torsoToHeightRatio * height / torsoHeightPx;
			// let cmPerPixel = shoulderWidthToHeightRatio * height / measurements[0].shoulderWidthPx;

			// Widths in cm
			measurements[0].shoulderWidth = cmPerPixel * measurements[0].shoulderWidthPx;
			measurements[0].chestWidth = cmPerPixel * measurements[0].chestWidthPx;
			measurements[0].waistWidth = cmPerPixel * measurements[0].waistWidthPx;
			measurements[0].hipsWidth = cmPerPixel * measurements[0].hipsWidthPx;

			// Calculate circumfrance values from cm widths
			let minorAxisChest = measurements[0].chestWidth / ellipseMajorToMinorAxisRatioChest;
			let minorAxisWaist = measurements[0].waistWidth / ellipseMajorToMinorAxisRatioWaist;
			let minorAxisHips = measurements[0].hipsWidth / ellipseMajorToMinorAxisRatioHips;

			measurements[0].Shoulder_cm = Math.ceil(measurements[0].shoulderWidth);
			measurements[0].Chest_cm = Math.ceil(circumfrance(measurements[0].chestWidth, minorAxisChest));
			measurements[0].Waist_cm = Math.ceil(circumfrance(measurements[0].waistWidth, minorAxisWaist));
			measurements[0].Hips_cm = Math.ceil(circumfrance(measurements[0].hipsWidth, minorAxisHips));

			resolve(measurements[0]);
		}
	);
}

export function storeMeasurement(measurement) {
	// TODO: store circumfrance values
	return firebase.firestore()
		.collection(`users/${firebase.auth().currentUser.uid}/measurements`)
		.add({
			timestamp: firebase.firestore.FieldValue.serverTimestamp(),
			...measurement,
		});
}

// Datapoint
export class Measurement {
	// TODO: clean-up
	constructor(sw, cw, ww, hw, srPx, slPx, crPx, clPx, wrPx, wlPx, hrPx, hlPx) {
		this.shoulderWidthPx = sw;
		this.chestWidthPx = cw;
		this.waistWidthPx = ww;
		this.hipsWidthPx = hw;
		this.shoulderRightPx = srPx;
		this.shoulderLeftPx = slPx;
		this.chestRightPx = crPx;
		this.chestLeftPx = clPx;
		this.waistRightPx = wrPx;
		this.waistLeftPx = wlPx;
		this.hipRightPx = hrPx;
		this.hipLeftPx = hlPx;
	}
}