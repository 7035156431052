import React, { useState } from 'react';
import { requestInvite } from '../../../lib/invites';

function validateEmail(email) {
	// eslint-disable-next-line
	var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

export default function InviteForm({ size }) {
	const [email, setEmail] = useState("");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [success, setSuccess] = useState(false);

	function onEmailChange(event) {
		setEmail(event.target.value);
	}

	function onSubmit() {
		if (!(email.trim())) {
			setError("Email address can not be empty.");
			return;
		}

		if (!validateEmail(email)) {
			setError("Please enter a valid email address.");
			return;
		}

		setError("");
		setSuccess(false);

		setLoading(true);
		requestInvite(email)
			.then(() => {
				setLoading(false);
				setSuccess(true);
				setEmail("");
			})
			.catch(error => {
				setLoading(false);
				setError(error.message);
			});
	}

	return (
		<>
			<div className="field">
				<div className="control is-expanded">
					<input
						value={email}
						onChange={onEmailChange}
						className={`input ${size}`} type="text" placeholder="Enter your email" />
				</div>
			</div>
			<div className="field">
				<div className="control auth-button">
					<button className={`button is-primary ${loading && 'is-loading'} ${size} is-outlined`}
						onClick={onSubmit}>
						Request invite
				</button>
				</div>
			</div>
			<p className="help is-danger">{error}</p>
			{success && <p className="help is-success">You successfully requested an invite.</p>}
		</>
	)
}