import React, { useState } from 'react';
import Text from '../../text';
import Redirect from '../../redirect';
import { signUpWithInvite } from '../../../lib/invites';
import { signIn } from '../../../lib/user/auth';
import { getUserName } from '../../../lib/user/name';
import Link from '../../link';
import Image from '../../image';

export default function SignupForm({ email, inviteKey }) {
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [success, setSuccess] = useState(false);
	const [gender, setGender] = useState('not-selected');

	function onPasswordChange(event) {
		setPassword(event.target.value);
	}

	function onGenderChange(event) {
		setGender(event.target.value);
	}

	function onSubmit() {
		setLoading(true);
		signUpWithInvite(gender, password, inviteKey)
			.then(() => signIn(email, password))
			.then(() => {
				setLoading(false);
				setSuccess(true);
				setError("");
			})
			.catch(error => {
				setError(error.message);
				setLoading(false);
				setSuccess(false);
			});
	}

	return (
		<div style={{ maxWidth: '340px', margin: 'auto' }}>
			<div className='auth-header'>
				<Link to='/'>
					<Image className='kleid-icon' src="/images/kleidSymbol.svg" />
				</Link>
				<Text h3>You got invited to try out kleid. You can now sign up below</Text>
			</div>

			<div className='auth-form'>
				<div className="form-body">
					<div className="field">
						<label className="label">Email</label>
						<div className="control">
							<p className="button is-static is-fullwidth"
								style={{ justifyContent: 'left' }}>
								{email}
							</p>
						</div>
					</div>
					<div className="field">
						<label className="label">Password</label>
						<div className="control is-expanded">
							<input className="input"
								value={password}
								onChange={onPasswordChange}
								type="password"
								placeholder="Choose a password" />
						</div>
					</div>
					<div className="field">
						<label className="label">Gender</label>
						<div class="control">
							<div class="select">
								<select value={gender} onChange={onGenderChange}>
									<option>Select gender</option>
									<option value="male">Male</option>
									<option value="female">Female</option>
								</select>
							</div>
						</div>
					</div>

					<div className="field ">
						<div className="control">
							<button onClick={onSubmit}
								className={`button is-primary is-outlined ${loading && 'is-loading'}`}>
								Sign up
							</button>
						</div>
					</div>

					<Text className="help is-danger">{error}</Text>
					{success && <Redirect to={`/${getUserName()}/size`} />}
				</div>
			</div>
		</div>
	);
}