import React, { useState } from 'react';
import Text from '../../text';
import Redirect from '../../redirect';
import { sendPasswordResetEmail } from '../../../lib/user/auth';
import Link from '../../link';
import Image from '../../image';
import { getUserName } from '../../../lib/user/name';


export default function ForgotPasswordForm() {
	const [success, setSuccess] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [email, setEmail] = useState("");

	function onEmailChange(event) {
		setEmail(event.target.value);
	}

	function onSubmit() {
		if (!email.trim()) {
			setError("The email address can not be empty.");
			return;
		}
		setLoading(true);
		sendPasswordResetEmail(email)
			.then(() => {
				setSuccess(true);
				setLoading(false);
			})
			.catch(err => {
				setSuccess(false);
				setError(err.message);
				setLoading(false);
			})
	}

	return (
		<div style={{ maxWidth: '340px', margin: 'auto' }}>

			<div className='auth-header'>
				<Link to='/'>
					{/*<Image className='kleid-icon' src="/images/kleidSymbol.svg" />*/}
				</Link>
				<Text h2>Reset password</Text>
			</div>
			<div className='auth-form'>
				<div className="form-body">

					<div className="field">
						<label className='label'>Email</label>
						<div className="control">
							<input className="input"
								value={email}
								onChange={onEmailChange}
								type="email" />
						</div>
					</div>

					<div className="field">
						<div className="control auth-button">
							<button onClick={onSubmit}
								className={`button is-primary is-outlined ${loading && 'is-loading'}`}>
								Request password reset
							</button>
						</div>
					</div>

					<Text className="help is-danger form-error-centered">{error}</Text>
					{success &&
						<Text className="help is-success has-text-centered">
							You will receive an email shortly with instructions on how to reset your password.
						</Text>
					}
				</div>
			</div>

		</div>
	);
}