import React, { useEffect, useState } from 'react';
import { fetchBrands } from '../../lib/brandsearch';
import Brand from './brand';
import { fetchMostRecentMeasurement } from '../../lib/user/measurements';
import Text from '../text';
import Button from '../button';
import Link from '../link';
import { getUserName } from '../../lib/user/name';
import Image from '../image';
import { getUserData } from '../../lib/user';
import Redirect from '../redirect';

export default function BrandSearch() {
	const [brands, setBrands] = useState(null);
	const [userMeasurement, setUserMeasurement] = useState(null);
	const [userData, setUserData] = useState(null);
	const [query, setQuery] = useState("");
	const [noMeasurementError, setNoMeasurementError] = useState(false);
	useEffect(() => {
		if (brands === null) {
			fetchBrands().then(setBrands);
		}

		if (userMeasurement === null) {
			fetchMostRecentMeasurement()
				.then(setUserMeasurement)
				.catch((error) => {
					console.warn(error);
					setNoMeasurementError(true);
				});
		}

		if (userData === null) {
			getUserData().then(setUserData)
				.catch((error) => {
					console.warn(error);
					setNoMeasurementError(true);
				});
		}
	}, [brands, userMeasurement, userData]);

	function onQueryChange(event) {
		setQuery(event.target.value);
	}

	if (noMeasurementError) {
		return (
			<Redirect to={`/${getUserName()}/size`} />
		);
	}

	return (
		<div style={{ maxWidth: '600px', textAlign: 'center', margin: 'auto' }}>
			<div className="field has-addons">
				<div className="control is-expanded">
					<input
						value={query}
						onChange={onQueryChange}
						className="input is-medium is-primary" type="text" placeholder="Search for a clothing brand" />
				</div>
			</div>

			{userData && brands && brands.map(brand => {
				// if a brand name does not match the query ignore the brand
				if (query && !brand.name.toLowerCase().includes(query)) {
					return null;
				}

				return (
					<Brand
						key={brand.name}
						brand={brand}
						userMeasurement={userMeasurement}
						gender={userData.gender} />
				);
			})}
		</div>
	);
}