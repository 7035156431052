import React from 'react';
import { withRouter } from 'react-router-dom';
import Navbar from './navbar';
import withTracker from './withTracker';
import UserAuth from '../user/auth';
import AuthContext from '../user/auth/context';
import { signIn, signOut } from '../../lib/user/auth';
import Footer from './footer';

const Page = withRouter(withTracker(function ({ children, noNav, noFooter }) {
	return (
		<UserAuth>
			{!noNav && <AuthContext.Consumer>
				{user => (
					<Navbar
						signedIn={Boolean(user)}
						signIn={signIn}
						signOut={signOut}
					/>
				)}
			</AuthContext.Consumer>}

			{children}

			{!noFooter && <Footer />}
		</UserAuth>
	);
}));

export default Page;